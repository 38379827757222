import { render, staticRenderFns } from "./import-substitution-card-open.vue?vue&type=template&id=7e1ebf16&scoped=true&"
import script from "./import-substitution-card-open.vue?vue&type=script&lang=js&"
export * from "./import-substitution-card-open.vue?vue&type=script&lang=js&"
import style0 from "./import-substitution-card-open.vue?vue&type=style&index=0&id=7e1ebf16&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e1ebf16",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-ext-old/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7e1ebf16')) {
      api.createRecord('7e1ebf16', component.options)
    } else {
      api.reload('7e1ebf16', component.options)
    }
    module.hot.accept("./import-substitution-card-open.vue?vue&type=template&id=7e1ebf16&scoped=true&", function () {
      api.rerender('7e1ebf16', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/import-substitution/import-substitution-card-open.vue"
export default component.exports