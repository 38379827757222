import { render, staticRenderFns } from "./Create.vue?vue&type=template&id=6edc3398&scoped=true&"
import script from "./Create.vue?vue&type=script&lang=js&"
export * from "./Create.vue?vue&type=script&lang=js&"
import style0 from "./Create.vue?vue&type=style&index=0&id=6edc3398&scoped=true&lang=css&"
import style1 from "./Create.vue?vue&type=style&index=1&id=6edc3398&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6edc3398",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-ext-old/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6edc3398')) {
      api.createRecord('6edc3398', component.options)
    } else {
      api.reload('6edc3398', component.options)
    }
    module.hot.accept("./Create.vue?vue&type=template&id=6edc3398&scoped=true&", function () {
      api.rerender('6edc3398', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/externalPurchases/Create.vue"
export default component.exports