import { render, staticRenderFns } from "./cabinet-fl223-proposals-list.vue?vue&type=template&id=78d81a55&"
import script from "./cabinet-fl223-proposals-list.vue?vue&type=script&lang=js&"
export * from "./cabinet-fl223-proposals-list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-ext-old/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('78d81a55')) {
      api.createRecord('78d81a55', component.options)
    } else {
      api.reload('78d81a55', component.options)
    }
    module.hot.accept("./cabinet-fl223-proposals-list.vue?vue&type=template&id=78d81a55&", function () {
      api.rerender('78d81a55', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/cabinets/fl223/cabinet-fl223-proposals-list.vue"
export default component.exports