import { render, staticRenderFns } from "./cabinet-notice-card-row-dual.vue?vue&type=template&id=004bcf76&"
import script from "./cabinet-notice-card-row-dual.vue?vue&type=script&lang=js&"
export * from "./cabinet-notice-card-row-dual.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-ext-old/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('004bcf76')) {
      api.createRecord('004bcf76', component.options)
    } else {
      api.reload('004bcf76', component.options)
    }
    module.hot.accept("./cabinet-notice-card-row-dual.vue?vue&type=template&id=004bcf76&", function () {
      api.rerender('004bcf76', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/cabinets/partials/cabinet-notice-card-row-dual.vue"
export default component.exports